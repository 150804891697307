<template>
  <modal
    :modalId="`modalFolderSub`"
    :modalTitle="propsModal.title"
    :load="false"
    :typeLg="`modal_md`"
    @closeModal="closeModal"
  >
    <div class="main">
      <div>
        <label for="Input" class="labelTitle"> Nome da Pasta</label>
        <input
          type="text"
          placeholder="..."
          id="Input"
          v-model="dataField.name"
        />
      </div>
      <div>
        <label for="" class="mt-3 mb-0">Visível para</label>
        <div class="checkContainer">
          <div>
            <input type="checkbox" id="master" disabled checked />
            <label for="master">Master</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="franqueado"
              value="2"
              @change="checkProfile($event)"
              :checked="dataField.profiles.some(dp => dp.id == 2 && dp.show)"
              :disabled="!dataField.profiles.some(dp => dp.id == 2)"
            />
            <label for="franqueado">Franqueado</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="vendedor"
              value="3"
              @change="checkProfile($event)"
              :checked="dataField.profiles.some(dp => dp.id == 3 && dp.show)"
              :disabled="!dataField.profiles.some(dp => dp.id == 3)"
            />
            <label for="vendedor">Vendedor</label>
          </div>
        </div>
      </div>
    </div>
    <actions
      :nameButton="propsModal.button"
      :disabled="disabled"
      @closeModal="closeModal"
      @submitform="submitform"
    />
  </modal>
</template>

<script>
import loader from "@/components/utils/_Load";
import modal from "@/components/modal/modalDefault";
import actions from "@/components/arquivos/files/acoes/botoes_rodape.vue";

export default {
  components: { loader, modal, actions },
  data() {
    return {
      disabled: false
    };
  },
  props: ["dataField", "propsModal", "load"],
  methods: {
    checkProfile(evt) {
      this.dataField.profiles.forEach(t => {
        if (t.id == evt.target.value) {
          t.show = !t.show;
        }
      });
    },
    closeModal() {
      this.$emit("closeModal", this.propsModal.cp);
    },
    submitform() {
      this.$emit(
        "submitform",
        this.propsModal.id,
        this.propsModal.category,
        this.propsModal.editFolder
      );
    }
  }
};
</script>

<style scoped>
.menu,
.menu-close {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

#checkbox-menu,
#btnClose {
  cursor: pointer;
  position: relative;
  display: block;
  height: 22px;
  width: 30px;
  margin: 0;
}

#btnClose span {
  position: absolute;
  display: block;
  border-radius: 30px;
  background: orange;
  transition: 0.25s ease-in-out;
  width: 36px;
  border: 2px solid orange;
}

#btnClose span:nth-child(1) {
  transform: rotate(-45deg);
  top: 8px;
}

#btnClose span:nth-child(2) {
  opacity: 0;
}

#btnClose span:nth-child(3) {
  transform: rotate(45deg);
  top: 8px;
}

#modalFolderSub .containerMain {
  background: #ffffff;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

#modalFolderSub .modal-header {
  border: none;
  display: flex;
  flex-direction: row-reverse;
}

.labelTitle {
  color: #a5a6ad !important;
}

#modalFolderSub h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #2474b2;
}

#modalFolderSub .main > div {
  display: flex;
  flex-direction: column;
}

#modalFolderSub .main .checkContainer {
  display: flex;
}

#modalFolderSub .main label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
  cursor: pointer;
}

#modalFolderSub .main input {
  background: #ffffff;
  border: 1px solid #98c4e6;
  box-sizing: border-box;
  border-radius: 6px;
  height: 42px;
  padding: 10px;
  margin-bottom: 10px;
  outline: none;
}

.checkContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px 10px 0;
}
.checkContainer div label {
  margin-left: 5px;
}

.btn {
  height: 40px;
  width: auto;
  border: none;
  border-radius: 6px;
  align-items: center;
  outline: none;
  width: 120px;
  padding: 0 20px;
}

.btn_secondary {
  color: #2474b2;
  border: #2474b2 1px solid;
}

.btn_default {
  background-color: #f38235;
}

.btn_default:hover {
  background-color: #e0a638;
}

.hoverFolder:hover {
  color: #f38235 !important;
  transform: translateY(-15px);
  -webkit-box-shadow: 5px 7px 10px 1px rgba(0, 0, 0, 0.39);
  box-shadow: 5px 7px 10px 1px rgba(0, 0, 0, 0.39);
}
</style>
